<template>
    <div class="login">
        <div class="logo">
            <img src="@/assets/img/logo.png" alt="">
        </div>
        <!-- 登录 -->
        <!-- <transition name = "fade"> -->
            <van-form v-if="type==0" @submit="onSubmit" class="login-con">
                <van-field
                    class="login-con-item"
                    v-model="username"
                    name="username"
                    label=""
                    placeholder="手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                    class="login-con-item"
                    v-model="password"
                    type="password"
                    name="password"
                    label=""
                    placeholder="密码"
                    :rules="[{ required: true, message: '请填写密码' }]"
                />
                <div style="margin: 16px;">
                    <van-button block type="primary" native-type="submit">
                    登录
                    </van-button>
                </div>
            </van-form>
        <!-- </transition> -->
        <!-- 注册 -->
        <transition name = "fade">
            <van-form v-if="type==1" @submit="onSubmitRegister" class="login-con">
                <van-field
                    class="login-con-item"
                    v-model="username"
                    name="username"
                    label=""
                    placeholder="手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                    class="login-con-item"
                    v-model="password"
                    type="password"
                    name="password"
                    label=""
                    placeholder="密码"
                    :rules="[{ required: true, message: '请填写密码' }]"
                />
                <van-field
                    class="login-con-item"
                    v-model="password_check"
                    type="password"
                    name="validatorPwd"
                    label=""
                    placeholder="确认密码"
                    :rules="[{ validator:validatorPwd, message: '密码不一致，请重新输入' }]"
                />
                <van-field
                    class="login-con-item"
                    v-model="invite_code"
                    name="invite_code"
                    label=""
                    placeholder="邀请码"
                    :rules="[{ required: true, message: '请填写邀请码' }]"
                />
                <div style="margin: 16px;">
                    <van-button block type="primary" native-type="submit">
                    注册
                    </van-button>
                </div>
            </van-form>
        </transition>

        <div class="foot_link" v-if="type==1" @click="changeType(0)">
            直接登录
        </div>
        <div class="foot_link" v-else @click="changeType(1)">
            注册账号
        </div>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        name: 'login',
        data() {
            return {
                type: 0, //1、代表注册页，0是登录页
                username: '',
                password: '',
                password_check:'',//确认密码
                invite_code:'',//yidian
            }
        },
        watch: {
            type(newValue, oldValue) {
                this.username = ''
                this.password = ''
                this.password_check =''
            }
        },
        methods: {
            validatorPwd(val){
                let {password,password_check} = this;
                console.log('password', this.password);
                console.log('password_check', this.password_check);
                if(password===password_check){
                    return true
                }else{
                    return false
                }
            },
            changeType(type){
                this.type = type
            },
            onSubmit(values) {
                console.log('submit', this.type);
                let param = {
                    username: this.username,
                    password: this.password,
                }
                this.$api.login({param,method:'POST'},res=>{
                    console.log(res.access_token);
                    if(res.status=='登录成功'){
                        localStorage.access_token = res.access_token;
                        localStorage.username = this.username;

                        Toast.success(res.status);
                        this.$router.push({path:'/main'}).then(()=>{
                            location.reload();
                        })
                    }else{
                        Toast.fail(res.message);
                    }
                    
                });
            },
            onSubmitRegister(values) {
                console.log('submit', this.type);
                let param = {
                    username: this.username,
                    password: this.password,
                    password_check: this.password_check,
                    invite_code: this.invite_code
                }
                this.$api.register({param,method:'POST'},res=>{
                    console.log(res.access_token);
                    if(res.status=="登录成功"){
                        localStorage.access_token = res.access_token;
                        localStorage.username = this.username;
                        Toast.success('注册并登录成功');
                        this.$router.push({path:'/main'})
                    }else{
                        Toast.fail(res.message);
                    }
                    
                });
            },
            
        },
    }
</script>

<style lang="less" scoped>
    .login{
        width: 100%;
        height: 100vh;
        min-height: 550px;
        background: url('./../assets/img/bg.jpg') center;
        background-size: cover;
        position: relative;
        .logo{
            height: 82px;
            padding-top: 56px;
            padding-bottom: 18px;
            img{
                height: 82px;
            }
        }
        .fade-enter-active, .fade-leave-active {
            transition: opacity 1s
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
            opacity: 0
        }
        &-con{
            width: 343px;
            margin: 0 auto;
            // height: 125px;
            background: rgba(255, 255, 255, 0.3);
            // background: #fff;
            position: absolute;
            top: 156px;
            margin-left: 50%;
            transform: translateX(-50%);
            border-radius: 8px;
            overflow: hidden;
            .van-cell{
                height: 62px;
                line-height: 62px;
                background: none;
            }
            /deep/.van-field__error-message{
                height: 26px;
                line-height: 26px;
                position: relative;
                top: -16px;
            }
            /deep/.van-field__control{
                height: 42px;
                line-height: 42px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fff;
            }
            &.hide{
                display: none;
            }
            &.on{
                transition: opacity 2s;
            }
            // &-item{
            //     // height: 62px;
            //     width: 295px;
            //     margin: 0 24px;
            //     background: none;
            //     position: relative;
            //     /deep/.van-field__error-message{
            //         position: absolute;
            //         top: 30px;
            //         z-index: 9;
            //     }
            // }
        }
        .foot_link{
            width: 100%;
            text-align: center;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #BFC1CC;
            line-height: 22px;
            position: absolute;
            bottom: 24px;
        }
    }
</style>
